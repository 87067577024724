






















































































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import UniversalService from "../../../common-app/service/UniversalService";
import { IDivision } from "../../../common-app/models/Division";
import { IDistrict } from "../../../common-app/models/District";
import { IThana } from "../../../common-app/models/Thana";
import { IDoctorChamber } from "../../models/DoctorChamber";
import DoctorChamberService from "../../service/DoctorChamberService";
import AlertService from "../../../common-app/service/AlertService";
import AuthUser from "../../../global-config/AuthUser";
import { Validator } from "vee-validate";
import TermsConditions from "../../components/TermsConditions.vue";

@Component({
  components:  {TermsConditions}
})
export default class Create extends Vue {
  public chamber: IDoctorChamber = {
    districtId: 0,
    thanaId: 0,
    whatsapp: "",
    viber:"",
    others:""
  } as IDoctorChamber;
  public divisions: IDivision[] = [];
  public districts: IDistrict[] = [];
  public thanas: IThana[] = [];
  public selectedThanas: IThana[] = [];
  public submited: boolean = false;
  public showVirtualChamberForm: boolean = false;
  public showVirtualConfirm: boolean = false;
  public preferredViber: boolean = false;
  public preferredWhatsapp: boolean = false;
  public errormessage: string = "";
  public viber: string = "";
  public submitted: boolean = false;
  public user = AuthUser.getAuthUser();
  public chambers: IDoctorChamber[] = [];
  public isVartualExist: boolean = false;
  public showTermsAndConditions:boolean = false;

  created() {
    const dict = {
      custom: {
        Name: {
          required: "Chamber name is required.",
        },
        Viber: {
          required: "Viber number is required.",
          numeric: "The viber number can only contain numeric characters.",
          min: "The viber number must be at least 11 characters.",
        },
        Whatsapp: {
          required: "Whatsapp number is required.",
          numeric: "The Whatsapp number can only contain numeric characters.",
          min: "The Whatsapp number must be at least 11 characters.",
        },
           Others: {
          required: "Others number is required.",
        },
      },
    };
    this.$validator.localize("en", dict);

    UniversalService.GetAddress().then((res) => {
      this.divisions = res.data.divisions;
      this.districts = res.data.districts;
      this.thanas = res.data.thanas;
    });
    // this.chamber.name = this.user.firstName;

    
  }
  


  chamberFrom() {
    this.$validator.validateAll().then((valid) => {
      if (valid) {
        this.submited = true;
        DoctorChamberService.insert(this.chamber)
          .then((res) => {
            AlertService.sendMessage({ status: true, message: "success" });
            this.$store.dispatch("addChamber", res.data.result);
           // console.log(this.$store);
            this.chamber = { districtId: 0, thanaId: 0 } as IDoctorChamber;
            this.submited = false;
            this.$validator.reset();
          })
          .catch((err) => {
            // alert("faild");
            AlertService.sendMessage("failed");
            this.submited = false;
          });
      }
    });
  }

  virtualChamberFrom() {
    if(this.chamber.isPreferredWhatsapp && this.chamber.isPreferredViber || this.chamber.isPreferredWhatsapp && this.chamber.isPreferredOthers || this.chamber.isPreferredViber&& this.chamber.isPreferredOthers){
      AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
      return;
    }
    
    if(this.chamber.whatsapp.length>0 && this.chamber.isPreferredWhatsapp)
     {
      this.showVirtualConfirm=true;
     }
     else if(this.chamber.viber.length>0 && this.chamber.isPreferredViber){
        this.showVirtualConfirm=true;
     }
     else if(this.chamber.others.length>0 && this.chamber.isPreferredOthers){
       this.showVirtualConfirm=true;
     }
     else{
       AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
     }
     if(!this.showVirtualConfirm){
       AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
     }
  

    // this.$validator.validateAll().then((valid) => {
    //   if(this.chamber.viber.length<1){
    //    AlertService.sendMessage("you can't add more than one virtual chamber");
    //    return;
    //    }
    //   if (valid) {
    //     this.showVirtualConfirm = true;
    //   }
    // });
  }

  submitFrom() {
    //  if(this.chamber.isPreferredWhatsapp && this.chamber.whatsapp=='')
    //  {
    //     this.errormessage="Whatsapp is required";
    //     this.showVirtualConfirm=false;
    //     return;
    //  }
    //  else if(this.chamber.isPreferredViber && this.chamber.viber==''){
    //     this.errormessage="Viber is required";
    //     this.showVirtualConfirm=false;
    //     return;
    //  }
    //  if(!this.chamber.isPreferredOthers && !this.chamber.others){
    //     this.showVirtualConfirm=false;
    //     // this.errormessage="Please select At least one Preferred";
    //     AlertService.sendMessage("Please select At least one Preferred");
    //    return;
    //  }
    this.submited = true;
    this.chamber.isVirtual = true;
    this.chamber.name="Virtual";
    this.chamber.thanaId=394;
    this.chamber.districtId=65;
    DoctorChamberService.insert(this.chamber)
      .then((res) => {
       // console.log(res);
        if (res.data.status) {
          AlertService.sendMessage({ status: true, message: "success" });
          this.$store.dispatch("addChamber", res.data.result);
          this.chamber = { districtId: 0, thanaId: 0 } as IDoctorChamber;
          this.submited = false;
          this.showVirtualConfirm = false;
          this.$validator.reset();
        } else {
          AlertService.sendMessage("you can't add more than one virtual chamber");
        }
      })
      .catch((err) => {
        // alert("faild");
        AlertService.sendMessage("failed");
        this.submited = false;
      });
  }

  public isVirtualChamber() {
    DoctorChamberService.GetAllChamberByUser().then((res) => {
      if (res.data.result) {
        this.chambers = res.data.result || [];
        this.chambers = this.chambers.filter((s) => s.isVirtual === true);
        if (this.chambers.length > 0) {
          this.isVartualExist = true;
        }
      }
    });
    return this.isVartualExist;
  }

  onChange(event: any) {
    this.selectedThanas = this.thanas.filter((s) => s.districtId === Number(event.target.value));
  }

  physicalChecked(val: boolean) {
    if (val == true) {
      this.showVirtualChamberForm = false;
    }
  }
  virtualChecked(val: boolean) {
    if (val == true) {
      this.showVirtualChamberForm = true;
    }
  }

  showTermsAndCond(){
    this.showTermsAndConditions = true;
  }
}
